import menuHeader from "./components/menu_header";
import Search from "./components/search.js";
import Cart from "./components/cart";
import Wishlist from "./components/wishlist";
import Compare from "./components/compare";
// import SubscribeForm from "./components/subscribe-form.js";
import SubscribeForm from "./components/new-subscribe-popup"
import modalProblem from "./components/modal-problem";
// import modalEmail from "./components/modal-email";
import cardProduct from "./components/card-product";
import ecommerceTracking from "./components/ecommerce-tracking";
import callbackButton from "./components/callbackButton";
import "./components/closeFromCopyElements"
import IMask from 'imask';

window.tracker = ecommerceTracking();

new Cart();
new Wishlist();
new Compare();
new Search();
new SubscribeForm();
menuHeader();
modalProblem();
// modalEmail();
callbackButton();
cardProduct();

const maskOptions = {
  mask: '+{38} (000) 000-00-00'
};

window.addEventListener('DOMContentLoaded', (event) => {
    const phoneInputs = document.querySelectorAll('.phoneMask');
    phoneInputs.forEach(input => {
      IMask(input, maskOptions);
    });
});
