export default function loginForm() {

    document.addEventListener('DOMContentLoaded', () => {
        let openModalButtons = document.querySelectorAll('.open-modal-problem');
        let modalClose = document.querySelector('.modal-problem_close');
        let modalWrapper = document.querySelector('.modal-problem_wrapper');

        if (openModalButtons.length > 0 && modalClose && modalWrapper) {
            openModalButtons.forEach(button => {
                button.addEventListener('click', function (e) {
                    e.preventDefault();
                    modalWrapper.classList.add('active');
                });
            });

            modalClose.addEventListener('click', function (e) {
                modalWrapper.classList.remove('active');
            });

            modalWrapper.addEventListener('click', function(event) {
                if (!event.target.closest('.modal-problem_block')) {
                    modalWrapper.classList.remove('active');
                }
            });
        }
    });

}
