export default class {
    constructor() {
        this.modal = document.getElementById("searchModal");
        this.searchInput = document.getElementById('searchInput');
        this.clearInput = document.getElementById('clearInput');
        this.searchResultBlock = document.querySelector('.search_result_block');
        this.searchResultsContainer = document.querySelector('.SearchResults');
        this.listResultContainer = this.searchResultsContainer.querySelector('.list_result');
        this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        this.form = document.getElementById('formSearchItems')
        this.searchButton = document.querySelector('.search_mob');
        this.searchContainer = document.querySelector('.search');
        this.handleInputDebounced = this.debounce(this.handleInput.bind(this), 300);
        this.router = window.router || {}
          this.initEvents();

      }

    initEvents() {
        // console.log(this.form)
        this.searchButton.addEventListener('click', () => this.openSearch());
        this.searchInput.addEventListener('input', () => this.handleInputDebounced());
        this.searchInput.addEventListener('keyup', (e) => this.redirectToCategory(e))
        this.clearInput.addEventListener('click', () => this.clearSearch());
        this.form.addEventListener('keydown', (e) => this.closePressEnterForm(e));
        window.addEventListener('click', (event) => this.closeModalOnClickOutside(event));
    }
    debounce(func, delay) {
        let timeoutId;
        return function(...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    }
    closePressEnterForm(e){
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation()
      }
    }

    openSearch() {
        this.searchContainer.classList.add('open');
        this.clearInput.style.display = "flex";
    }

    redirectToCategory(e) {
        e.preventDefault()
        e.stopPropagation()
        if(e.key === 'Enter') {

            // const list = document.querySelectorAll('.result_list_category a')
            // if(list) {
            //     const item = list[0]
            //     if(!item) return
            //     const link = item.getAttribute('href')
            //     const url = new URL(link)

            //     url.searchParams.set('search',e.target.value)
            //     window.location.href = url.href
            // }
            const searchQuery = this.searchInput.value.trim();

            // Перевіряємо, чи довжина значення принаймні 2 символи
            if (searchQuery.length >= 2) {
                // Формуємо URL
                const url = `/product/search?search=${encodeURIComponent(searchQuery)}`;

                // Редіректимо на новий URL
                window.location.href = url;
            }
        }

    }

    handleInput() {
        const query = this.searchInput.value;
        this.listResultContainer.innerHTML = '';
        this.modal.style.display = "block";
        this.clearInput.style.display = "flex";

        if (query.length > 0) {
            this.listResultContainer.innerHTML = '<div class="loader">Завантаження...</div>';
            this.fetchSearchResults(query);
        } else {
            this.modal.style.display = "none";
            this.clearInput.style.display = "none";
        }
    }

    clearSearch() {
        this.modal.style.display = "none";
        this.searchInput.value = '';
        this.clearInput.style.display = "none";
        this.searchContainer.classList.remove('open');
    }

    closeModalOnClickOutside(event) {
        if (event.target === this.modal) {
            this.clearSearch();
        }
    }

    fetchSearchResults(query) {
        const string = new URLSearchParams({
            'query': query
        }).toString();
        const url = this.router.search || '/search';
        const requestUrl = `${url}?${string}`;

        this.requestServer(requestUrl);
    }

    updateSearchCategory(categories) {
        const categoryContainer = document.querySelector('.result_list_category');
        categoryContainer.innerHTML = '';

        categories.forEach(category => {
            const categoryLink = document.createElement('a');
            categoryLink.href = `${category.url}`;
            categoryLink.textContent = category.name;
            categoryContainer.appendChild(categoryLink);
        });
    }

    requestServer(requestUrl) {
        fetch(requestUrl, {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': this.token,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    if (data.view === '') {
                        this.listResultContainer.innerHTML = '<div class="loader">Нічого не знайдено</div>';
                    } else {
                        this.listResultContainer.innerHTML = data.view;
                        this.updateSearchCategory(data.categories)
                    }

                }

            })
            .catch(error => console.error('Error:', error));
    }
}
