import Swiper from 'swiper';
import 'swiper/css';

export default function additionalCarousel() {
  const parents = document.querySelectorAll('.additional-products');
  const carousels = document.querySelectorAll('.additionalCarousel');

  parents.forEach((parentItem, index) => {
    const carousel = parentItem.querySelector('.additionalCarousel');
    const head = parentItem.querySelector('.additional-products__head')

    const swiper = new Swiper(carousel, {
      slidesPerView: 4,
      slidesPerGroup: 1, // Кількість слайдів, які будуть прокручуватися за раз
      spaceBetween: 8,
      breakpoints: {
        0: {
          slidesPerView: 1.5,
          spaceBetween: 6
        },
        375: {
          slidesPerView: 1.5,
        },
        400: {
          slidesPerView: 2.1,
        },
        420: {
          slidesPerView: 2.3,
        },
        480: {
          slidesPerView: 2.8,
        },
        560: {
          slidesPerView: 3.1,
        },
        640: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2.8,
        },
        1024: {
          slidesPerView: 4,
        }
      },
    });
    head.addEventListener('click', () => {
      parentItem.classList.toggle('active')
      
    });
  });
}
