export default function cardProduct() {
    document.addEventListener('DOMContentLoaded', function() {
        if (window.innerWidth >= 768) {
            const imageWrappers = document.querySelectorAll('.card_product');
            imageWrappers.forEach(function(wrapper) {
                const hoverImage = wrapper.getAttribute('hover-image');
                const mainImage = wrapper.getAttribute('main_image');

                if (hoverImage) {
                    wrapper.addEventListener('mouseenter', function() {
                        mainImage.style.opacity = 0;
                        hoverImage.style.opacity = 1;
                    });

                    wrapper.addEventListener('mouseleave', function() {
                        mainImage.style.opacity = 1;
                        hoverImage.style.opacity = 0;
                    });
                }
            });
        }
    });
}
