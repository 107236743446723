export default function callbackButton() {
    document.addEventListener('DOMContentLoaded', function () {
        var callbackButton = document.querySelector('.callback_btn');
        var widgetCallback = document.querySelector('.widget_callback');
        let currentIcon = 1;

        callbackButton.addEventListener('click', function () {
            widgetCallback.classList.toggle('open');
        });

        document.getElementById('ringostat-call-button').addEventListener('click', function(e) {
            e.preventDefault();
            document.querySelector('.messengers-icon-btn--tel').click();
            widgetCallback.classList.remove('open');
        });

        document.addEventListener('click', function(e) {
            if (!callbackButton.contains(e.target) && !widgetCallback.contains(e.target) && widgetCallback.classList.contains('open')) {
                widgetCallback.classList.remove('open');
            }
        });
    });
}
