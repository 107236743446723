/*export default function menuHeader() {
    const burger = document.querySelector('.burger');
    const menu = document.querySelector('.menu_desktop');
    const btn_close_menu = document.querySelector('.btn_close_menu');

    burger.addEventListener('click', function() {
        menu.classList.add('open');
        document.body.classList.add('hidden')
    });
    btn_close_menu.addEventListener('click', function() {
        menu.classList.remove('open');
        document.body.classList.remove('hidden')
    });

    document.body.onclick = function(event) {
        if (event.target === menu) {
            menu.classList.remove('open');
            document.body.classList.remove('hidden')
        }
    }

    if( window.innerWidth < 768 ){
        const categoryMenu = menu.querySelector('.category_menu');
        const titleMenu = categoryMenu.querySelector('.title_menu');
        const backMenu = categoryMenu.querySelector('.back');

        titleMenu.addEventListener('click', function() {
            categoryMenu.classList.add('active');
        });
        backMenu.addEventListener('click', function() {
            categoryMenu.classList.remove('active');
        });
    }
}*/

export default function menuHeader() {
  const burger = document.querySelector('.burger');
  const menu = document.querySelector('.menu_desktop');
  const btnCloseMenu = document.querySelector('.btn_close_menu');
  const categoryMenu = menu?.querySelector('.category_menu');
  const titleMenu = categoryMenu?.querySelector('.title_menu');
  const backMenu = categoryMenu?.querySelector('.back');

  const toggleMenu = (isOpen) => {
    menu.classList.toggle('open', isOpen);
    document.body.classList.toggle('hidden', isOpen);
  };

  const toggleCategoryMenu = (isActive) => {
    categoryMenu?.classList.toggle('active', isActive);
  };

  burger?.addEventListener('click', () => toggleMenu(true));
  btnCloseMenu?.addEventListener('click', () => toggleMenu(false));

  document.body.addEventListener('click', (event) => {
    if (event.target === menu) {
      toggleMenu(false);
    }
  });

  if (window.innerWidth < 768 && categoryMenu) {
    titleMenu?.addEventListener('click', () => toggleCategoryMenu(true));
    backMenu?.addEventListener('click', () => toggleCategoryMenu(false));
  }
}
