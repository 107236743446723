import ecommerceTracking from "./ecommerce-tracking.js";

export default class {
    constructor() {
        this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        this.addListeners();
        this.tracker = ecommerceTracking();
    }

    addListeners() {
        this.setupWishlistToggle();
        this.setupCloseClick();
        this.setupOutsideClick();
        this.setupAddToWishlistClick();
        this.setupRemoveFromCart();
        this.continueShopping();
    }

    updateWishlistView(html) {
        const cartBody = document.querySelector('.wishlist_body');
        if (cartBody) {
            cartBody.innerHTML = html;
        }
    }

    setupWishlistToggle() {
        document.querySelectorAll('.favorite').forEach(button => {
            button.addEventListener('click', () => {
                document.querySelector('.wishlist_block_wrapper').style.display = 'block';
            });
        });
    }

    openWishlistModal() {
        document.querySelector('.wishlist_block_wrapper').style.display = 'block';
    }

    sendProductDataLayer(productId, eventName) {
        const productData = {
            id: productId,
            quantity: 1
        };

        this.tracker.sendEvent({items: [productData]}, eventName);
    }

    setupCloseClick() {
        const closeBtn = document.querySelector('.wishlist_block_close');
        if (closeBtn) {
            closeBtn.addEventListener('click', () => {
                document.querySelector('.wishlist_block_wrapper').style.display = 'none';
            });
        }
    }

    continueShopping() {
        const cartBlock = document.querySelector('.wishlist_block');

        if (!cartBlock) return;

        cartBlock.addEventListener('click', (e) => {
            const continueShopping = e.target.closest('.continue_shopping_wishlist');
            if (continueShopping) {
                e.preventDefault();
                document.querySelector('.wishlist_block_wrapper').style.display = 'none';
            }
        });
    }

    setupOutsideClick() {
        document.addEventListener('click', (event) => {
            const cartBlockWrapper = document.querySelector('.wishlist_block_wrapper');
            if (!event.target.closest('.wishlist_block') && !event.target.closest('.favorite')) {
                cartBlockWrapper.style.display = 'none';
            }
        });
    }

    setupAddToWishlistClick() {
        const siteMain = document.querySelector('.site-main');
        if (!siteMain) return;

        siteMain.addEventListener('click', (e) => {
            const button = e.target.closest('.wishlist');
            if (button) {
                e.preventDefault();
                const productId = button.getAttribute('data-product-id');
                const isAlreadyInWishlist = button.classList.contains('active');

                if (isAlreadyInWishlist) {
                    button.classList.remove('active');
                    this.removeFromWishlist(productId);
                } else {
                    button.classList.add('active');
                    this.addToWishlist(productId);
                }
            }
        });
    }


    setupRemoveFromCart() {
        const cartBlock = document.querySelector('.wishlist_block');

        if (!cartBlock) return;

        cartBlock.addEventListener('click', (e) => {
            const button = e.target.closest('.card_paid-products-priceTotal-delete');
            if (button) {
                e.preventDefault();
                const productId = button.getAttribute('data-product-id');
                this.removeFromWishlist(productId);
            }
        });
    }

    updateCount(count) {
        let wishlistCounts = document.querySelectorAll('.favoriteCount');
        wishlistCounts.forEach(wishlistCount => {
            wishlistCount.innerText = count;
            if(count === 0) {
                wishlistCount.style.display = 'none';
            } else {
                wishlistCount.style.display = 'block';
            }
        });
    }


    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    addToWishlist(productId) {
        if (window.App && window.App.auth) {
            this.updateWishlistAuthUser(productId);
        } else {
            let wishlist = JSON.parse(this.getCookie('wishlist')) || {};

            if (wishlist[productId]) {
                delete wishlist[productId];
            } else {
                wishlist[productId] = true;
            }
            this.setCookie('wishlist', JSON.stringify(wishlist), 7);
            this.updateWishlistWithCookie(wishlist);

            // let showModal = localStorage.getItem('modalShown') !== 'true';
            // let productCount = Object.keys(wishlist).length;
            //
            // if (showModal && productCount === 2) {
            //     this.openOfferRegisterPopup();
            //     localStorage.setItem('modalShown', 'true');
            // }
        }
        this.sendProductDataLayer(productId, 'add_to_wishlist');
    }


    updateWishlistWithCookie(cookie) {
        const cookieString = JSON.stringify(cookie);
        const query = new URLSearchParams({
            'cookie': cookieString
        }).toString();
        const url = '/get-product-wishlist';
        const requestUrl = `${url}?${query}`;

        this.requestServer(requestUrl);
    }

    removeFromWishlist(productId) {
        if (window.App && window.App.auth) {
            this.removeWishlistAuthUser(productId);
        } else {
            let wishlist = JSON.parse(this.getCookie('wishlist')) || {};
            if (wishlist[productId]) {
                delete wishlist[productId];
            }
            this.setCookie('wishlist', JSON.stringify(wishlist), 7);
            this.updateWishlistWithCookie(wishlist);
        }
        this.removeActiveClassWishlist(productId);
    }

    updateWishlistAuthUser(productId, quantity) {
        const query = new URLSearchParams({
            'product_id': productId,
            'quantity': quantity
        }).toString();
        const url = '/get-product-user-wishlist';
        const requestUrl = `${url}?${query}`;

        this.requestServer(requestUrl);
        this.addActiveClassWishlist(productId);
    }
    removeWishlistAuthUser(productId) {
        const query = new URLSearchParams({
            'product_id': productId
        }).toString();
        const url = '/remove-product-user-wishlist';
        const requestUrl = `${url}?${query}`;

        this.requestServer(requestUrl);
        this.removeActiveClassWishlist(productId);
    }

    addActiveClassWishlist(productId) {
        document.querySelectorAll(`.wishlist[data-product-id="${productId}"]`).forEach(element => {
            element.classList.add('active');
        });
    }
    removeActiveClassWishlist(productId) {
        document.querySelectorAll(`.wishlist[data-product-id="${productId}"]`).forEach(element => {
            element.classList.remove('active');
        });
    }

    requestServer(requestUrl) {
        fetch(requestUrl, {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': this.token,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.updateWishlistView(data.view);
                    this.updateCount(data.count)
                }

            })
            .catch(error => console.error('Error:', error));
    }

    openOfferRegisterPopup() {
        const modal = document.querySelector('.modal-free-delivery-popup-log-in');
        if (modal) {
            modal.style.display = 'flex';

            const closeButton = modal.querySelector('.modal-free-delivery-popup-log-in_close');
            closeButton.addEventListener('click', () => {
                modal.style.display = 'none';
                this.showDeliveryPopupAfterDelay()
            });

            const registerBtn = modal.querySelector('.register_btn');
            registerBtn.addEventListener('click', () => {
                modal.style.display = 'none';
                setTimeout(function(){
                    document.getElementById("account-icon").click();
                    document.getElementById("register-popup-tab").click();
                }, 300)
            });

            window.addEventListener('click', (event) => {
                if (event.target === modal) {
                    modal.style.display = 'none';
                    this.showDeliveryPopupAfterDelay()
                }
            });
        }
    }

    showDeliveryPopupAfterDelay() {
        setTimeout(() => {
            const modal = document.querySelector('.modal-free-delivery-popup');
            if (modal) {
                modal.style.display = 'flex';

                const closeButton = modal.querySelector('.modal-free-delivery-popup_close');
                closeButton.addEventListener('click', () => {
                    modal.style.display = 'none';
                });

                window.addEventListener('click', (event) => {
                    if (event.target === modal) {
                        modal.style.display = 'none';
                    }
                });

                const form = modal.querySelector('.form_free_delivery');
                const emailInput = document.getElementById('form-group-email-input');

                form.addEventListener('submit', function (e) {
                    e.preventDefault();
                    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                    const formData = new FormData(form);
                    formData.append('_token', token);
                    formData.append('bonus_type', 'free_delivery');

                    if (validateEmail(emailInput)) {
                        fetch('/email-bonus', {
                            method: 'POST',
                            headers: {
                                'X-CSRF-TOKEN': token,
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0'
                            },
                            body: formData
                        })
                            .then(response => response.json())
                            .then(data => {
                                if (data.success) {
                                    form.innerHTML = `<div class="success_message">${data.message}</div>`
                                } else {
                                    showError(emailInput, data.message)
                                }
                            })
                            .catch(error => {
                                console.error('There has been a problem with your fetch operation:', error);
                            });
                    }
                });
            }
        }, 600000); // 600000 мс = 10 хв
    }

}


function validateEmail(input) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(input.value)) {
        showError(input, 'Невірний формат email');
        return false;
    }
    clearError(input)
    return true;
}

function showError(input, message) {
    const errorSpan = input.parentElement.parentElement.querySelector('.form-group-error');
    if (errorSpan) {
        input.classList.add('error')
        errorSpan.textContent = message;
        errorSpan.style.display = 'block';
    }
}

function clearError(input) {
    const errorSpan = input.parentElement.parentElement.querySelector('.form-group-error');
    if (errorSpan) {
        input.classList.remove('error')
        errorSpan.textContent = '';
        errorSpan.style.display = 'none';
    }
}
