export default function ecommerceTracking() {

    function viewItemList(items) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ecommerce',
            event_stage: 'view_item_list',
            ecommerce: {
                items: items
            }
        });
    }

    function selectItem(id, name, price, category) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ecommerce',
            event_stage: 'select_item',
            ecommerce: {
                items: [{
                    item_name: name,
                    item_id: id,
                    price: price,
                    currency: 'UAH',
                    item_brand: 'Defence Ukraine',
                    item_category: category,
                    item_list_name: 'SelectItem',
                    item_list_id: id,
                    quantity: '1'
                }]
            }
        });
    }

    function viewItem(id, name, price, category) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ecommerce',
            event_stage: 'view_item',
            ecommerce: {
                items: [{
                    item_name: name,
                    item_id: id,
                    price: price,
                    currency: 'UAH',
                    item_brand: 'Defence Ukraine',
                    item_category: category,
                    item_list_name: 'Product',
                    item_list_id: id,
                    quantity: '1'
                }]
            }
        });
        if (typeof fbq !== 'undefined') {
            fbq('track', 'ViewContent', {
                content_type: 'product',
                content_ids: [id],
                content_name: price,
                content_category: category,
                value: price,
                currency: 'UAH' //Todo change to dynamic if needed
            });
        }
    }

    function sendEvent(items, eventName) {

        const productsData = items.items.map(item => ({
            id: item.id,
            quantity: item.quantity
        }));

        const productsDataEncoded = encodeURIComponent(JSON.stringify(productsData));

        const url = '/get-products-datalayer';
        const requestUrl = `${url}?productsData=${productsDataEncoded}`;

        requestServer(requestUrl, function (data) {
            if (data && data.success) {
                const adaptedItems = data.items.map((item, index) => ({
                    item_name: item.name,
                    item_id: item.id,
                    price: item.price,
                    currency: 'UAH',
                    item_brand: 'Defence Ukraine',
                    item_category: item.category_name,
                    item_list_name: eventName,
                    item_list_id: eventName,
                    index: index,
                    quantity: item.quantity
                }));

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'ecommerce',
                    event_stage: eventName,
                    ecommerce: {
                        items: adaptedItems
                    }
                });
            } else {
                console.error('Error fetching product data');
            }
        });
    }

    function beginCheckout(items) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ecommerce',
            event_stage: 'begin_checkout',
            ecommerce: {
                items: items
            }
        });
    }

    function completePurchase(orderID,isNew = false) {

        const url = '/get-order-datalayer';
        const requestUrl = `${url}?orderId=${orderID}`;

        requestServer(requestUrl, function (data) {
            if (data && data.success) {
                const adaptedItems = data.order.items.map((item, index) => ({
                    item_name: item.name,
                    item_id: item.id,
                    price: item.price,
                    currency: 'UAH',
                    item_brand: 'Defence Ukraine',
                    item_category: item.category_name,
                    item_list_name: 'purchase',
                    item_list_id: 'purchase',
                    index: index,
                    quantity: item.quantity
                }));

                const userEmail = data.order.user.user_email;
                const userPhone = data.order.user.user_phone;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    new_customer: isNew ? 'True' : 'False',
                    event: 'ecommerce',
                    event_stage: 'purchase',
                    email: userEmail,
                    phone_number: userPhone,
                    ecommerce: {
                        // transaction_id: data.order.id,
                        transaction_id: data.order.number,
                        value: data.order.total_price,
                        currency: 'UAH',
                        items: adaptedItems
                    }
                });
            } else {
                console.error('Error fetching product data');
            }
        });

    }

    function requestServer(requestUrl, callback) {
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        fetch(requestUrl, {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': token,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
        })
            .then(response => response.json())
            .then(data => {
                callback(data);
            })
            .catch(error => {
                console.error('Error:', error);
                callback(null);
            });
    }

    return {
        viewItem,
        selectItem,
        viewItemList,
        sendEvent,
        beginCheckout,
        completePurchase
    };
}
