export default class {
    constructor() {
        this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        this.addListeners();
        this.initializeFromCookie();
    }

    initializeFromCookie() {
        const compare = this.getCookie('compare') || {};
        Object.entries(compare).forEach(([productId, categoryId]) => {
            this.addActiveClassCompare(productId, categoryId);
        });
        this.updateCount(Object.keys(compare).length);
    }
    addListeners() {
        this.setupAddToCompareClick();
        this.setupRemoveFromCart();
    }
    addActiveClassCompare(productId, categoryId) {
        document.querySelectorAll(`.compare[data-product-id="${productId}"][data-category-id="${categoryId}"]`).forEach(element => {
            element.classList.add('active');
        });
    }
    setupAddToCompareClick() {
        const siteMain = document.querySelector('.site-main');
        if (!siteMain) return;

        siteMain.addEventListener('click', (e) => {
            const button = e.target.closest('.compare');
            if (button) {
                e.preventDefault();
                const productId = button.getAttribute('data-product-id');
                const categoryId = button.getAttribute('data-category-id');
                const isAlreadyInCompare = button.classList.contains('active');

                if (isAlreadyInCompare) {
                    document.querySelectorAll(`.compare[data-product-id="${productId}"][data-category-id="${categoryId}"]`).forEach(element => {
                        element.classList.remove('active');
                    });
                    this.removeFromCompare(productId, categoryId);
                } else {
                    document.querySelectorAll(`.compare[data-product-id="${productId}"][data-category-id="${categoryId}"]`).forEach(element => {
                        element.classList.add('active');
                    });
                    this.addToCompare(productId, categoryId);
                }
            }
        });
    }
    addToCompare(productId, categoryId) {
        let compare = this.getCookie('compare') || {};
        compare[productId] = categoryId;
        this.setCookie('compare', compare, 7);

        this.updateCompareWithCookie(compare);
        this.updateCount(Object.keys(compare).length);
    }
    removeFromCompare(productId, categoryId) {
        let compare = this.getCookie('compare') || {};
        delete compare[productId];
        this.setCookie('compare', compare, 7);
        this.updateCompareWithCookie(compare);
        this.updateCount(Object.keys(compare).length);
    }
    setupRemoveFromCart() {
        const cartBlock = document.querySelector('.Compare_block');
        if (!cartBlock) return;

        cartBlock.addEventListener('click', (e) => {
            const button = e.target.closest('.card_paid-products-priceTotal-delete');
            if (button) {
                e.preventDefault();
                const productId = button.getAttribute('data-product-id');
                const categoryId = button.getAttribute('data-category-id');
                this.removeFromCompare(productId, categoryId);
            }
        });
    }

    updateCount(count) {
        let comparisonCounts = document.querySelectorAll('.comparisonCount');
        comparisonCounts.forEach(comparisonCount => {
            comparisonCount.innerText = count;
            if (count === 0) {
                comparisonCount.style.display = 'none';
            } else {
                comparisonCount.style.display = 'block';
            }
        });
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) {
                const cookieValue = c.substring(nameEQ.length, c.length);
                try {
                    return JSON.parse(cookieValue);
                } catch (error) {
                    return {};
                }
            }
        }
        return {};
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        const serializedValue = JSON.stringify(value);
        document.cookie = name + "=" + serializedValue + expires + "; path=/";
    }

    updateCompareWithCookie(cookie) {
        const entries = Object.entries(cookie);
        const queryParams = new URLSearchParams();

        entries.forEach(([productId, categoryId]) => {
            queryParams.append(productId, categoryId);
        });

        const queryString = queryParams.toString();
    }

}
