import { reject } from "lodash";

export default class SubscriptionModal {
  constructor() {
      this.modal = document.querySelector('.new-popup');
      this.subscribeFooter = document.querySelector('.subscribe_footer');
      this.closeButton = document.querySelector('.close-button');
      this.emailForm = this.modal.querySelector('#subscribe_email_form');
      this.emailInput = this.modal.querySelector('#email_subscribe');
      this.phoneForm = this.modal.querySelector('#subscribe_phone_form');
      this.phoneInput = this.modal.querySelector('#phone_subscribe')
      this.overlay = this.modal.querySelector('.overlay')
      this.close = this.modal.querySelector('.new-popup__close')
      this.skipPhone = this.modal.querySelector('.no-phone-js')
      // this.errorMessage = document.getElementById('error-message-subscription');
      this.reopenButton = document.createElement('button');
      // this.alreadyButton = this.modal.querySelector('.already_button');
      // this.subscribeBlock = this.modal.querySelector('.subscribe_block');
      // this.thankyouBlock = this.modal.querySelector('.thankyou_block');
      // this.alreadyBlock = this.modal.querySelector('.already_block');
      this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      this.subscribed = false;

      // this.alreadyButtonFooter = this.subscribeFooter?.querySelector('.already_button_footer');
      // this.subscribeBlockFooter = this.subscribeFooter?.querySelector('.subscribe_block');
      // this.thankyouBlockFooter = this.subscribeFooter?.querySelector('.thankyou_block');
      // this.alreadyBlockFooter = this.subscribeFooter?.querySelector('.already_block');
      // this.formFooter = this.subscribeFooter?.querySelector('.group_input_footer');
      // this.emailInputFooter = this.subscribeFooter?.querySelector('.input_subscribe_footer');
      // this.errorMessageFooter = document.getElementById('error-message-subscription-footer');

      this.setupReopenButton();
      this.initEvents();
  }

  setupReopenButton() {
      this.reopenButton.textContent = "";
      this.reopenButton.className = 'reopen-button';
      document.body.appendChild(this.reopenButton);
      this.reopenButton.addEventListener('click', () => this.toggleModal(true));
  }

  initEvents() {
      document.addEventListener('DOMContentLoaded', this.showSubscriptionModal.bind(this));

      this.closeButton.addEventListener('click', () => {
          this.toggleModal(false);
          if(!this.subscribed) {
              this.setCookie('subscribed', 'closed', 30);
          }
      });

      this.emailForm.addEventListener('submit', this.handleFormEmailSubmit.bind(this));
      this.phoneForm.addEventListener('submit', this.handleFormPhoneSubmit.bind(this));
      this.skipPhone.addEventListener('click', this.skipPhoneForm.bind(this));
      this.close.addEventListener('click', (event) => {
        this.toggleModal(false);
            if(!this.subscribed) {
                this.setCookie('subscribed', 'closed', 30);
            }
        });
      // this.overlay.addEventListener('click', this.toggleModal(false));
      // this.alreadyButton.addEventListener('click', this.handleChangeBlock.bind(this));


      // this.formFooter?.addEventListener('submit', this.handleFormSubmitFooter.bind(this));
      // this.alreadyButtonFooter?.addEventListener('click', this.handleChangeBlockFooter.bind(this));

      window.addEventListener('click', (event) => {
          if (event.target === this.overlay) {
              this.toggleModal(false);
              if(!this.subscribed) {
                  this.setCookie('subscribed', 'closed', 30);
              }
          }
      });
  }

  skipPhoneForm(event){
    event.preventDefault();
    if(this.modal.classList.contains('second-step'))this.modal.classList.remove('second-step');
    this.modal.classList.add('third-step');

  }

  handleFormEmailSubmit(event) {
      event.preventDefault();
      if (this.validateEmail(this.emailInput.value)) {
          this.submitSubscription({email:this.emailInput.value , phone:null});
          this.modal.classList.remove('error');
      } else {
          this.modal.classList.add('error');
      }
  }
  handleFormPhoneSubmit(event){
    event.preventDefault();
    this.submitSubscriptionPhone({phone:this.phoneInput.value , email:this.emailInput.value});
    if(this.modal.classList.contains('error'))this.modal.classList.remove('error');
  }
  // handleFormSubmitFooter(event) {
  //     event.preventDefault();
  //     if (this.validateEmail(this.emailInputFooter.value)) {
  //         this.submitSubscriptionFooter(this.emailInputFooter.value);
  //         this.errorMessageFooter.classList.add('hidden');
  //     } else {
  //         this.errorMessageFooter.classList.remove('hidden');
  //     }
  // }

  // handleChangeBlock(event) {
  //     event.preventDefault();
  //     this.subscribeBlock.style.display = 'flex';
  //     this.alreadyBlock.style.display = 'none';
  // }

  // handleChangeBlockFooter(event) {
  //     event.preventDefault();
  //     this.subscribeBlockFooter.style.display = 'flex';
  //     this.alreadyBlockFooter.style.display = 'none';
  // }

  validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
  }

  submitSubscription({email }) {
      const formData = new FormData();
      formData.append('_token', this.token);
      formData.append('bonus_type', 'discount');
      if(email)formData.append('email', email);
      formData.append('type', 'Popup');

      fetch('/bonus/email', {
          method: 'POST',
          headers: {
              'X-CSRF-TOKEN': this.token,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': '0',
              'Accept': 'application/json'
          },
          body: formData
      })
          .then(response => response.json())
          .then(data => {
              if(data.success) {
                  this.setCookie('subscribed', 'true', 30);
                  this.reopenButton.classList.add('hidden');
                  this.modal.classList.add('second-step');
                  if(this.modal.classList.contains('error'))this.modal.classList.remove('error');

                  this.subscribed = true;
              } else if(!data.success && data.already) {
                  this.setCookie('subscribed', 'true', 30);
                  this.reopenButton.classList.add('hidden');
                  if(this.modal.classList.contains('error'))this.modal.classList.remove('error');

                //   if(this.modal.classList.contains('second-step'))this.modal.classList.remove('second-step');
                  this.modal.classList.add('second-step');
                  this.subscribed = true;
              }

          })
          .catch(error => {
              console.error('There has been a problem with your fetch operation:', error);
              this.modal.classList.add('error');
          });
  }


  async submitSubscriptionPhone({ email, phone }) {
    const formData = new FormData();
    formData.append('_token', this.token);
    formData.append('bonus_type', 'discount');
    if (phone) formData.append('phone', phone);
    formData.append('type', 'Popup');

    try {
        const response = await fetch(`/bonus/phone/${email}`, {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': this.token,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
                'Accept': 'application/json'
            },
            body: formData
        });

        const data = await response.json();
        if (data.success) {
            this.setCookie('subscribed', 'true', 30);
            this.reopenButton.classList.add('hidden');
            if (this.modal.classList.contains('error')) this.modal.classList.remove('error');
            this.modal.classList.add('third-step');
            this.subscribed = true;
        }else{
            this.modal.classList.add('error');
        }
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        this.modal.classList.add('error');
    }
}


  // submitSubscriptionFooter(email) {
  //     const formData = new FormData();
  //     formData.append('_token', this.token);
  //     formData.append('bonus_type', 'discount');
  //     formData.append('email', email);
  //     formData.append('type', 'Footer');

  //     fetch('/email-bonus', {
  //         method: 'POST',
  //         headers: {
  //             'X-CSRF-TOKEN': this.token,
  //             'Cache-Control': 'no-cache',
  //             'Pragma': 'no-cache',
  //             'Expires': '0'
  //         },
  //         body: formData
  //     })
  //         .then(response => response.json())
  //         .then(data => {
  //             if(data.success) {
  //                 this.setCookie('subscribed', 'true', 30);
  //                 this.modal.classList.add('second-step');
  //                 this.subscribed = true;
  //             } else if(!data.success && data.already) {
  //                 this.setCookie('subscribed', 'true', 30);
  //                 if(this.modal.classList.contains('second-step'))this.modal.classList.remove('second-step');
  //                 this.modal.classList.add('third-step');
  //                 this.subscribed = true;
  //             }

  //         })
  //         .catch(error => {
  //             console.error('There has been a problem with your fetch operation:', error);
  //         });
  // }

  showSubscriptionModal() {
      const subscriptionStatus = this.getCookie('subscribed');

      if(subscriptionStatus === 'true') {
          this.subscribed = true;
          this.reopenButton.classList.add('hidden');
      } else if(subscriptionStatus === 'closed') {
          this.reopenButton.classList.remove('hidden');
      } else {
          setTimeout(() => {
              this.modal.classList.add('active');
          }, 15000);
      }
  }

  toggleModal(show) {
      if (show) {
          this.modal.classList.add('active');
      } else {
          this.modal.classList.remove('active');
          if(this.modal.classList.contains('second-step'))this.modal.classList.remove('second-step')
          if(this.modal.classList.contains('third-step'))this.modal.classList.remove('third-step')
      }
  }

  setCookie(name, value, days) {
      let expires = "";
      if (days) {
          let date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  getCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
  }
}
