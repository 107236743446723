const allowedSelectors = ['.products-title'];

const body = document.querySelector('body.can-select')

function isAllowedToCopy(element) {
    return allowedSelectors.some(selector => element.matches(selector));
}

window.addEventListener('DOMContentLoaded', (event) => {
  if(!body){
    document.addEventListener('contextmenu', function(e) {
      if (!isAllowedToCopy(e.target)) {
          e.preventDefault();
      }
    });
    document.addEventListener('keydown', function(e) {
        if (e.ctrlKey && e.key === 'c') {
            if (!isAllowedToCopy(e.target)) {
                e.preventDefault();
            }
        }
    });
  }
});


