import ecommerceTracking from "./ecommerce-tracking";
import additionalCarousel from "./additionals-swiper";

additionalCarousel()

let body = document.querySelector('body')

export default class {
  constructor() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.addListeners();
    this.tracker = ecommerceTracking();
    this.router = window.CartRoute || {};
  }

  addListeners() {
    this.setupCartToggle();
    this.setupCloseClick();
    this.setupOutsideClick();
    this.setupIncrementDecrement();
    this.setupAddToCartClick();
    this.setupRemoveFromCart();
    this.continueShopping();
    this.setupRepeatOrderClick();
    this.AddTogetherCheaperToCart();
    this.updateMaxCount();
  }

  updateCartView(html) {
    const cartBody = document.querySelector('.cart_body');
    if (cartBody) {
      cartBody.innerHTML = html;
    }
    additionalCarousel()
  }

  updateMaxCount() {
    const inputs = document.querySelectorAll('.quantity-input');
    const maxLength = 3;
    if (inputs) {
      inputs.forEach(input => {
        input.addEventListener('input', function () {
          if (input.value.length > maxLength) {
            input.value = input.value.substring(0, maxLength);
          }
        });
      })
    }
  }

  setupCartToggle() {
    const cart = document.querySelector('.cart');
    if (cart) {
      cart.addEventListener('click', () => {
        this.sendDataLayer('view_cart');
        document.querySelector('.cart_block_wrapper').style.display = 'block';
        body.classList.add('hidden')
      });
    }
  }

  openCartModal() {
    document.querySelector('.cart_block_wrapper').style.display = 'block';
    body.classList.add('hidden')
  }

  sendDataLayer(eventName) {
    const cartPaidProducts = document.querySelector('.card_paid-products');
    if (!cartPaidProducts) return;

    const items = cartPaidProducts.querySelectorAll('.card_paid-products-items');
    let productsIds = [];
    items.forEach(item => {
      const itemId = item.getAttribute('data-product-id');
      const itemQuantity = parseInt(item.getAttribute('data-quantity'), 10);
      productsIds.push({
        id: itemId,
        quantity: itemQuantity
      });
    });
    this.tracker.sendEvent({items: productsIds}, eventName);
  }

  sendProductDataLayer(productId, eventName) {
    const productData = {
      id: productId,
      quantity: 1
    };

    this.tracker.sendEvent({items: [productData]}, eventName);
  }


  setupRepeatOrderClick() {
    // const siteMain = document.querySelector('.site-main');
    if (!body) return;

    body.addEventListener('click', (e) => {
      const button = e.target.closest('.repeat_order');
      if (button) {
        e.preventDefault();

        const profileOrder = button.closest('.profile_order');
        if (!profileOrder) return;

        const items = profileOrder.querySelectorAll('.item');
        items.forEach(item => {
          const itemId = item.getAttribute('data-product-id');
          const itemQuantity = item.getAttribute('data-quantity');
          this.addToCart(itemId, itemQuantity);
        });
      }
    });
  }

  setupCloseClick() {
    const closeBtn = document.querySelector('.cart_block_close');
    if (closeBtn) {
      closeBtn.addEventListener('click', (e) => {
        e.preventDefault();
        document.querySelector('.cart_block_wrapper').style.display = 'none';
        body.classList.remove('hidden')
      });
    }
  }

  continueShopping() {
    const cartBlock = document.querySelector('.cart_block');

    if (!cartBlock) return;

    cartBlock.addEventListener('click', (e) => {
      const continueShopping = e.target.closest('.continue_shopping');
      if (continueShopping) {
        e.preventDefault();
        document.querySelector('.cart_block_wrapper').style.display = 'none';
        body.classList.remove('hidden')
      }
    });
  }

  setupOutsideClick() {
    document.addEventListener('click', (event) => {
      const cartBlockWrapper = document.querySelector('.cart_block_wrapper');
      if (cartBlockWrapper && !event.target.closest('.cart_block') && !event.target.closest('.cart')) {
        cartBlockWrapper.style.display = 'none';
        body.classList.remove('hidden')
      }
    });
  }

  setupIncrementDecrement() {
    const cartBlock = document.querySelector('.cart_block');

    if (!cartBlock) return;

    cartBlock.addEventListener('click', (e) => {
      const decrementBtn = e.target.closest('#decrementBtn');
      const incrementBtn = e.target.closest('#incrementBtn');

      if (decrementBtn || incrementBtn) {
        const quantityInput = decrementBtn ? decrementBtn.nextElementSibling : incrementBtn.previousElementSibling;
        const productId = decrementBtn ? decrementBtn.getAttribute('data-product-id') : incrementBtn.getAttribute('data-product-id');
        const currentValue = parseInt(quantityInput.value, 10);

        if (decrementBtn && currentValue > 1) {
          this.updateQuantity(productId, currentValue - 1);
        } else if (incrementBtn) {
          this.updateQuantity(productId, currentValue + 1);
        }
      }

    });

    cartBlock.addEventListener('change', (e) => {
      const quantityInput = e.target.closest('.quantity-input');
      if (quantityInput) {
        this.handleQuantityChange(quantityInput);
      }
    });
  }

  handleQuantityChange(input) {
    let newQuantity = parseInt(input.value, 10);
    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = 1;
      input.value = newQuantity;
    }
    const productId = input.getAttribute('data-product-id');
    this.updateQuantity(productId, newQuantity);
  }

  updateQuantity(productId, newQuantity) {
    // if (window.App && window.App.auth) {
    this.updateQuantityCartAuthUser(productId, newQuantity);
    /*} else {
        let cart = JSON.parse(this.getCookie('cart')) || {};
        if (cart[productId]) {
            cart[productId] = newQuantity;
            this.setCookie('cart', JSON.stringify(cart), 7);
            this.updateCartWithCookie(cart);
        }
    }*/
  }

  setupAddToCartClick() {
    // const siteMain = document.querySelector('.site-main');
    if (!body) return;
    body.addEventListener('click', (e) => {
      const button = e.target.closest('.add_to_cart');
      if (button) {
        e.preventDefault();
        const productId = button.getAttribute('data-product-id');
        const quantity = button.getAttribute('data-quantity') ? parseInt(button.getAttribute('data-quantity'), 10) : 1;
        this.addToCart(productId, quantity);
      }
    });
  }

  AddTogetherCheaperToCart() {
    // const siteMain = document.querySelector('.site-main');
    if (!body) return;
    body.addEventListener('click', (e) => {
      const button = e.target.closest('.together__add');
      if (button) {
        e.preventDefault();
        const cartId = button.getAttribute('data-cart-id');
        const productIds = button.getAttribute('data-cart-ids');
        const quantity = button.getAttribute('data-quantity') ? parseInt(button.getAttribute('data-quantity'), 10) : 1;
        this.addToCart(cartId, quantity, productIds);
      }
    });
  }


  setupRemoveFromCart() {
    const cartBlock = document.querySelector('.cart_block');

    if (!cartBlock) return;

    cartBlock.addEventListener('click', (e) => {
      const button = e.target.closest('.card_paid-products-priceTotal-delete');
      if (button) {
        e.preventDefault();
        const productId = button.getAttribute('data-product-id');
        const productCartId = button.getAttribute('data-cart-id');
        if (productId) this.removeFromCart(productId);
        if (productCartId) this.removeFromCartBundle(productCartId);
      }
    });
  }

  updateCount(count) {

    let cartCount = document.getElementById('cartCount');
    cartCount.innerText = count;
    if (count === 0) {
      cartCount.style.display = 'none';
    } else {
      cartCount.style.display = 'block';
    }
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  addToCart(productId, quantity, productIds) {
    // if (window.App && window.App.auth) {
    this.updateCartAuthUser(productId, quantity, productIds)
    /*} else {
        let cart = JSON.parse(this.getCookie('cart')) || {};
        if (cart[productId]) {
            cart[productId] += quantity;
        } else {
            cart[productId] = quantity;
        }
        this.setCookie('cart', JSON.stringify(cart), 7);
        this.updateCartWithCookie(cart)
    }*/

    if (!productIds) this.sendProductDataLayer(productId, 'add_to_cart');
    //  if (productIds)this.sendProductDataLayer(productId, 'add_to_cart');
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  // updateCartWithCookie(cookie) {
  //   const cookieString = JSON.stringify(cookie);
  //   const query = new URLSearchParams({
  //     'cookie': cookieString
  //   }).toString();
  //   const url = '/get-product-cart';
  //   const requestUrl = `${url}?${query}`;
  //
  //   this.requestServer(requestUrl);
  // }

  removeFromCart(productId) {
    // if (window.App && window.App.auth) {
    this.removeCartAuthUser(productId);
    /*} else {
        let cart = JSON.parse(this.getCookie('cart')) || {};
        if (cart[productId]) {
            delete cart[productId];
        }
        this.setCookie('cart', JSON.stringify(cart), 7);
        this.updateCartWithCookie(cart);
    }*/
    this.sendProductDataLayer(productId, 'remove_from_cart');
  }

  removeFromCartBundle(cartId) {
    let form = {
      'cart_id': cartId,
    }
    fetch('/api/cart/product/remove', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': this.token,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Accept': 'application/json'
      },
      body: JSON.stringify(form)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.updateCartView(data.view);
          this.openCartModal();
          this.updateCount(data.count)
          this.updateMaxCount()
        } else {
          throw new Error(data.status + ' ' + data.error);
        }

      })
      .catch(error => console.error('Error:', error));
  }

  updateCartAuthUser(productId, quantity, productIds) {
    console.log(this.router)
    if (!productIds) {
      const query = new URLSearchParams({
        'product_id': productId,
        'quantity': quantity
      }).toString();
      const url = this.router.show || '/get-product-user-cart';
      const requestUrl = `${url}?${query}`;

      this.requestServer(requestUrl);
    }
    if (productIds) {
      let form = {
        'product_id': productId,
        'product_ids': JSON.parse(productIds),
        'quantity': quantity
      }

      const url = '/api/cart/add-bundle';
      this.requestServerForTogether(url, form);
    }

  }

  removeCartAuthUser(productId) {
    const query = new URLSearchParams({
      'product_id': productId
    }).toString();
    const url = this.router.remove || '/remove-product-user-cart';
    const requestUrl = `${url}?${query}`;

    this.requestServer(requestUrl);
  }

  updateQuantityCartAuthUser(productId, quantity) {
    const query = new URLSearchParams({
      'product_id': productId,
      'quantity': quantity
    }).toString();
    const url = this.router.update || '/update-product-user-cart';
    const requestUrl = `${url}?${query}`;

    this.requestServer(requestUrl, {
      'Accept': 'application/json'
    });
  }

  requestServerForTogether(requestUrl, form) {
    fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': this.token,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Accept': 'application/json'
      },
      body: JSON.stringify(form)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.updateCartView(data.view);
          this.openCartModal();
          this.updateCount(data.count);
          this.updateMaxCount()
        } else {
          throw new Error(data.status + ' ' + data.error);
        }

      })
      .catch(error => console.error('Error:', error));
  }

  requestServer(requestUrl, headers = {}) {
    fetch(requestUrl, {
      method: 'GET',
      headers: {
        ...headers,
        'X-CSRF-TOKEN': this.token,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.updateCartView(data.view);
          this.openCartModal();
          this.updateCount(data.count)
          this.updateMaxCount()
        } else {
          throw new Error(data.status + ' ' + data.error);
        }

      })
      .catch(error => console.error('Error:', error));
  }
}
